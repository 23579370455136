@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap);
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

:root {
  --color-light: rgba(251, 249, 255, 0.898);
  --color-dark: #000000;
  --color-primary: #31b5d6;
  --color-secondary: #a53169;
  --main-transition: all 0.2s ease-in-out;
  --bg-light: #fbf9ffe5;
}

body {
  overflow-x: hidden;
  min-height: 100vh;
  background: #000000;
  background: var(--color-dark);
}

a {
  text-decoration: none;
  color: #31b5d6;
  color: var(--color-primary);
}

ul {
  list-style: none;
}

h1,
h2,
h3 {
  font-weight: 500;
}

.dark-mode {
  background: #000000;
  background: var(--color-dark);
  color: rgba(251, 249, 255, 0.898);
  color: var(--color-light);
}

.btn-primary {
  background: #31b5d6;
  background: var(--color-primary);
  color: rgba(251, 249, 255, 0.898);
  color: var(--color-light);
  padding: 0.7rem 1rem;
  border-radius: 5px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  transition: var(--main-transition);
}

.btn-primary:hover {
  transform: scale(1.05) rotate(2deg);
}
.btn-primary.dark {
  color: #000000;
  color: var(--color-dark);
}

